import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultPageContentComponent } from './default-page-content.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        DefaultPageContentComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: [
        DefaultPageContentComponent
    ]
})
export class DefaultPageContentModule { }
