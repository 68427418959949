import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {
    Building,
    PhysicalObject,
    PhysicalObjectType
} from '../../pages/energy-monitoring/interfaces/building.interface';
import {buildings, physicalObjects} from './data';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DigitalTwinApi
{
    private readonly _buildings: Building[];
    private readonly _physicalObjects: PhysicalObject[];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Set the data
        this._physicalObjects = physicalObjects;
        this._buildings = buildings;
        // Register Mock API handlers
        this.registerHandlers();
    }

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // Building - GET
        this._fuseMockApiService
            .onGet(environment.dataRetrieverUrl + 'buildings')
            .reply(() => {

                // Clone the data to preserve the originals
                const buildingClone = cloneDeep(this._buildings);

                // Return
                return [200, buildingClone];
            });

        // PhysicalObjects - GET
        this._fuseMockApiService
            .onGet(environment.dataRetrieverUrl + 'physical-objects')
            .reply(({request}) => {
                const physicalObjType = request.params.get('type');
                let physicalObjectsClone = [];

                // Clone the data to preserve the originals
                if (physicalObjType) {
                    physicalObjectsClone = cloneDeep(this._physicalObjects.filter(obj => obj.type === PhysicalObjectType[physicalObjType]));
                } else {
                    physicalObjectsClone = cloneDeep(this._physicalObjects);
                }

                // Return
                return [200, physicalObjectsClone];
            });
    }
}
