import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { anomalies } from 'app/mock-api/anomalies/data';
import { Anomaly } from '../../pages/anomaly-detection/models/anomaly.interface';
import { RestRepositoryResponse } from '../../models/rest-repository-response.interface';
import { environment } from '../../../environments/environment';
import { ModelTrainingPayload } from '../../models/model-training-payload';

@Injectable({
    providedIn: 'root'
})
export class AnomaliesMockApi {
    private readonly _anomalies: RestRepositoryResponse<Anomaly>;
    private readonly _defaultAlg = 'DBSCAN';

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Set the data
        this._anomalies = anomalies as any;
        // Register Mock API handlers
        this.registerHandlers();
    }

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // Anomalies - GET
        this._fuseMockApiService
            .onGet('api/anomalies')
            .reply(() => {

                // Clone the data to preserve the originals
                const anomaliesClone = cloneDeep(this._anomalies);

                // Return
                return [200, anomaliesClone];
            });

        // Train new model - POST
        this._fuseMockApiService
            .onPost(environment.anomalyRepositoryUrl + `train-models/${this._defaultAlg}`)
            .reply(({request}) => {
                const body = request.body as ModelTrainingPayload;
                // Add model key
                body.trainedModelKey = 'model-key';
                return [200, body];
            });
    }
}
