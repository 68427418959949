import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';
import { AppAuthGuard } from './core/auth/auth.guard';
import { PilotGuard } from './core/auth/pilot.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Authenticated routes
    {
        path: '',

        /** Before uncomment this section set realm and client_id in:
         *  starter-project-V14\src\environments\environment.ts
         */

        canActivate: [AppAuthGuard],
        canActivateChild: [AppAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                canActivate: [PilotGuard],
                loadChildren: (): Promise<any> =>
                    import('app/pages/energy-monitoring/energy-monitoring.module').then(m => m.EnergyMonitoringModule)
            },
            {
                path: 'measure-and-validate', loadChildren: (): Promise<any> =>
                    import('app/pages/measure-and-validate/measure-and-validate.module').then(m => m.MeasureAndValidateModule)
            },
            {
                path: 'predictive-maintenance', loadChildren: (): Promise<any> =>
                    import('app/pages/anomaly-detection/anomaly-detection.module').then(m => m.AnomalyDetectionModule)
            },
            {
                path: 'comfort-optimizer', 
                canActivate: [PilotGuard],
                loadChildren: (): Promise<any> =>
                    import('app/pages/comfort-optimizer/comfort-optimizer.module').then(m => m.ComfortOptimizerModule)
            },
        ]
    }

];
