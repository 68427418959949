<div class="flex flex-col flex-auto min-w-0 m-10 gap-10">
    <div class="flex items-center justify-between w-full">
        <div>
            <div class="text-3xl font-semibold tracking-tight leading-8">
                {{ title }}
            </div>
            <div class="font-medium tracking-tight text-secondary">
                {{ subtitle }}
            </div>
        </div>
        <ng-container *ngTemplateOutlet="actions">
        </ng-container>
    </div>
    <ng-content></ng-content>
</div>
