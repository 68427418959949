import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of, ReplaySubject } from 'rxjs';
import { ALLOWED_PILOTS } from '../auth/allowedPilots';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private keycloakAngular: KeycloakService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        const mockNav: FuseNavigationItem[] = [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'mat_solid:home',
                link: '/dashboard',
                hidden: () => this.isPageHidden('dashboard')
            },
            {
                id: 'predictive-maintenance',
                title: 'Predictive Maintenance',
                type: 'basic',
                icon: 'mat_solid:online_prediction',
                link: '/predictive-maintenance'
            },
            {
                id: 'comfort-optimizer',
                title: 'Comfort Optimizer',
                type: 'basic',
                icon: 'mat_solid:settings_input_component',
                link: '/comfort-optimizer',
                hidden: () => this.isPageHidden('comfort-optimizer')
            },
            // {
            //     id: 'measure-validate',
            //     title: 'Measure & Validate',
            //     type: 'basic',
            //     icon: 'mat_solid:query_stats',
            //     link: '/measure-and-validate'
            // }
        ];

        const nav = {
            default: mockNav,
            compact: mockNav,
            futuristic: mockNav,
            horizontal: mockNav
        } as Navigation;

        this._navigation.next(nav);
        return of(nav);
    }

    private isPageHidden(page: 'dashboard' | 'comfort-optimizer'): boolean {
        const tokenObj = this.keycloakAngular.getKeycloakInstance()?.tokenParsed;
        return !ALLOWED_PILOTS[page]?.includes(tokenObj?.['pilotId']);
    }
}
