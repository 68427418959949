import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'pr-default-page-content',
  templateUrl: './default-page-content.component.html'
})
export class DefaultPageContentComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @ContentChild('actions') actions: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
