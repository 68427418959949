import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { actionsRoles } from './actionsRoles'

@Injectable({
    providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard implements CanActivate, CanLoad {
    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
        super(router, keycloakAngular);
    }

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    //canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    //{
    //    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    //    return this.checkAccess(redirectUrl);
    //}

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.checkAccess(childRoute.data.action);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this.checkAccess(route.data.action);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.checkAccess(route.data.action);
    }

    async checkAccess(action: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.keycloakAngular.isLoggedIn().then(auth => {
                this.authenticated = auth;
                if (!this.authenticated) {
                    reject("User not authenticated");
                    this.keycloakAngular.login();
                }            
                this.roles = this.keycloakAngular.getUserRoles(true);
                const granted = this.checkRolesForAction(action);
                if (!granted) {
                    reject("Access not allowed")
                    this.router.navigate(['/site'])
                }
                resolve(true)
            })
        })
    }

    checkRolesForAction(action: string): boolean {
        let granted = false;
        if (!action) {
            granted = true;
        } else {
            const requiredRole = actionsRoles[action];
            if (requiredRole && this.roles.includes(requiredRole)) {
                granted = true;
            }
        }
        return granted;
    }

}