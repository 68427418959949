import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslocoCoreModule} from 'app/core/transloco/transloco.module';
import {NgApexchartsModule} from 'ng-apexcharts';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {DefaultPageContentModule} from './default-page-content/default-page-content.module';
import {SectionTitleModule} from './section-title/section-title.module';
import {SectionHeaderModule} from './section-header/section-header.module';
import {MatTooltipModule} from '@angular/material/tooltip';


const MATERIAL_MODULES = [
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatTooltipModule
];

const PRELUDE_CUSTOM_MODULES = [
    DefaultPageContentModule,
    SectionHeaderModule,
    SectionTitleModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoCoreModule,
        NgApexchartsModule,
        ...PRELUDE_CUSTOM_MODULES,
        ...MATERIAL_MODULES
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoCoreModule,
        NgApexchartsModule,
        ...PRELUDE_CUSTOM_MODULES,
        ...MATERIAL_MODULES
    ],
    declarations: [

    ]
})
export class SharedModule
{
}
