import { PowerProfile as PowerProfile } from 'app/pages/comfort-optimizer/models/power-profile.interface';
import { Measurement } from './telemetry.interface';

interface Building {
    uuid: string;
    altitude: number;
    longitude: number;
    latitude: number;
    buildingSpaces: BuildingSpace[];
}

interface BuildingSpace {
    uuid: string;
    size: number;
    sizeUnitOfMeasure: string;
    physicalObjects: PhysicalObject[];
    devices?: Device[];
}

interface PhysicalObject {
    uuid: string;
    name: string;
    manufacturer?: string;
    model?: string;
    type?: string;
    nominalConsumption?: NominalConsumption;
    nominalPower?: number;
    devices?: Device[];
    powerProfile?: PowerProfile;
}

interface Device {
    uuid: string;
    manufacturer: string;
    model: string;
    status: string;
    sensors: Sensor[];
}

interface NominalConsumption {
    measurementProperty: string;
    value: number;
    unitOfMeasure: string;
}

interface Sensor {
    uuid: string;
    manufacturer: string;
    model: string;
    measurementProperty: string;
    measurement?: Measurement;
}

enum PhysicalObjectType {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    APPLIANCE = 'APPLIANCE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    POWER_LINE = 'POWER_LINE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CABINET = 'CABINET',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PV_SYSTEM = 'PV_SYSTEM',
}

export {
    Building,
    BuildingSpace,
    PhysicalObject,
    Device,
    Sensor,
    PhysicalObjectType,
    NominalConsumption,
};
