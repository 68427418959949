import {Component, Inject} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {TranslocoService} from '@ngneat/transloco';
import { environment } from 'environments/environment';

@Component({
    selector   : 'pr-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    constructor(
        public titleService: Title,
        private _adapter: DateAdapter<any>,
        private transloco: TranslocoService,
        @Inject(MAT_DATE_LOCALE) private _locale: string,
    )
    {
        this.titleService.setTitle(environment.appTitle);
        this.transloco.langChanges$.subscribe((lang: string) => {
            this._adapter.setLocale(lang);
        });
    }
}
