import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';

/** 
 * Service used to control the login/logout status on Keycloak
 * At load time trigger a loadProfile from userService
 */
@Injectable({ providedIn: 'root' })
export class AuthService {    
    private logged = new BehaviorSubject<boolean>(false);
    loggedChange$ = this.logged.asObservable();

    constructor(
        private readonly keycloak: KeycloakService,
        private _userService: UserService
    ) { }

    login(): Promise<void> { 
        return this.keycloak.login().then(() => {
            this.loadProfile();
        }); 
    }

    logout(): Promise<void> { return this.keycloak.logout(window.location.origin); }

    loadProfile(): Promise<User> {
        return new Promise<User>((resolve,reject) => {
            this.keycloak.loadUserProfile().then(userProfile => {
                    let user: User = userProfile;
                    user.name = user.firstName + " " + user.lastName;
                    user.avatar = null;
                    this._userService.user = user;
                    resolve(user);
                }
            );
        });
    }
}
