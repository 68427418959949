// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppConfig } from 'app/core/config/app.config';
import {KeycloakConfig} from 'keycloak-js';


/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
export const appConfig: AppConfig = {
    layout: 'futuristic',
    scheme: 'light',
    theme: 'custom'
};

const keycloakConfig: KeycloakConfig = {
    url: 'https://keycloak.stamtech.dev/auth',
    realm: 'PRELUDE',
    clientId: 'prelude-frontend'
};

export const environment = {
    production: false,
    isMock: false,
    fuseConfig: appConfig,
    keycloak: keycloakConfig,
    dataRetrieverUrl: 'data-retriever/',
    anomalyRepositoryUrl: 'anomaly-repo/',
    anomalyDetectionUrl: 'anomaly-detection/',
    appTitle: 'Prelude',
    comfortOptimizerUrl: 'comfort-optimizer-service/',
    powerProfileUrl: 'power-profile/',
    solcastService: {
        url: 'solcast-service/',
        apiKey: 'dvU5gE_Y-tTAP5x5OFvfm7b7BcIOY0iv',
        endpoint: 'radiation/forecasts'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
