<div class="flex items-center gap-2">
    <div class="flex items-center justify-center w-8 h-8 bg-neonYellow rounded-full">
        <mat-icon class="icon-size-7 text-white">
            {{ iconName }}
        </mat-icon>
    </div>
    <div class="text-2xl font-semibold tracking-tight leading-6">
        {{ title }}
    </div>
</div>
