import {Injectable} from '@angular/core';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {cloneDeep} from 'lodash-es';
import {Telemetry} from '../../pages/energy-monitoring/interfaces/telemetry.interface';
import {telemetries} from './data';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HemsApi
{
    private readonly _telemetries: Telemetry[];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Set the data
        this._telemetries = telemetries;
        // Register Mock API handlers
        this.registerHandlers();
    }

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // Navigation - GET
        this._fuseMockApiService
            .onGet(environment.dataRetrieverUrl + 'sensors/:sensorUuid/telemetries')
            .reply(() => {

                // Clone the data to preserve the originals
                const telemetriesClone = cloneDeep(this._telemetries);

                // Randomize telemetry value
                telemetriesClone.forEach(t => t.measurement.value += generateRandomValue());

                // Return
                return [200, telemetriesClone];
            });
    }
}

const generateRandomValue = (min = 0.00, max= 2.00): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};


