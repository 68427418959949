import { KeycloakProfile } from "keycloak-js";

export class User implements KeycloakProfile
{
    id?: string;
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    enabled?: boolean;
    emailVerified?: boolean;
    totp?: boolean;
    createdTimestamp?: number;
    name?: string;
    avatar?: string;
    status?: string;
}
