export enum Role {
    User = 'user',
    Admin = 'admin'
}

/**
 * List of actions
 * For each action a role is required
 * App.Authguard will use this actions to authorize Route
 * authActionDirective will hide components if the user hasn't the role
 */
export const actionsRoles = {
    'pages': Role.User
}