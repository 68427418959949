import {Component, Inject, OnInit} from '@angular/core';
import {FuseAlertAppearance, FuseAlertType} from '../../../@fuse/components/alert';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {CustomSnackbarInput} from './custom-snackbar-input';

@Component({
  selector: 'pr-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styles: [
  ]
})
export class CustomSnackbarComponent implements OnInit {
  type: FuseAlertType = 'error';
  appearance: FuseAlertAppearance = 'border';
  title: string = '';
  message: string = '';
  showIcon: boolean = true;

  constructor(public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarInput) {
    const { type, appearance, title, message, showIcon } = data;
    this.type = type;
    this.appearance = appearance;
    this.title = title;
    this.message = message;
    this.showIcon = showIcon;
  }

  ngOnInit(): void {
  }

}
