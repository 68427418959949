import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { ALLOWED_PILOTS } from './allowedPilots';

@Injectable({
  providedIn: 'root'
})
export class PilotGuard implements CanActivate {
  private readonly ALTERNATIVE_LANDING_ROUTE = '/predictive-maintenance';

  constructor(protected router: Router, protected keycloakAngular: KeycloakService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tokenObj = this.keycloakAngular.getKeycloakInstance()?.tokenParsed;

    for (const page of Object.keys(ALLOWED_PILOTS)) {
      const pagePaths = route.url.flatMap(url => url.path);
      if (pagePaths.includes(page) && ALLOWED_PILOTS[page].includes(tokenObj?.['pilotId'])) {
        return true;
      }
    };

    return this.router.navigateByUrl(this.ALTERNATIVE_LANDING_ROUTE);
  }

}
