import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CustomSnackbarModule } from './custom-snackbar/custom-snackbar.module';


const initializeKeycloak = (keycloak: KeycloakService) => (): Promise<boolean> =>
    keycloak.init({
        config: environment.keycloak,
        initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            checkLoginIframe: false
        },
        bearerExcludedUrls: [environment.solcastService.url]
    });


@NgModule({
    imports: [
        IconsModule,
        TranslocoCoreModule,
        KeycloakAngularModule,
        MatSnackBarModule,
        MatMomentDateModule,
        CustomSnackbarModule
    ],
    providers: [

        /** Before uncomment this section set realm and client_id in:
         *  starter-project-V14\src\environments\environment.ts
         */

        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter }
    ],
    declarations: []
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
